export const displayHeader = [{ key: "createdAt", display_name: "Oluşturma Tarihi" }, { key: "adi", display_name: "Adı" }, { key: "kodu", display_name: "Kodu" }, { key: "bakanlik_kodu", display_name: "B. Kodu" }, { key: "havuz_adeti", display_name: "Havuz Adeti" }]
export const displayData = [
    { key: "havuz_mikrobiyoloji_ozel", display_name: "Havuz Mik. Özel" },
    { key: "gida", display_name: "Gıda" },
    { key: "su_mik", display_name: "Su Mik" },
    { key: "su_kim", display_name: "Su Kim" },
    { key: "swap", display_name: "Swap" },
    { key: "mantar", display_name: "Mantar" },
    { key: "leg", display_name: "Legionella" },
    { key: "ortam_petrisi", display_name: "Ortam Petrisi" },
]