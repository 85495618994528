import React,{useContext} from 'react';
import {  
    RtableCellHeading,
    RtableCellContent,  
    RtableCell,
} from './style'
import create_UUID from '../../utils/uuid'
import PermissionContext from '../../store/permissionContext';
import TesisContext from '../../store/tesis/tesisContext'

export default function CellTesisDetail({value,baslik,name,_id,i}){
    const { editperm } = useContext(PermissionContext);
    const { updateTesis } = useContext(TesisContext);
    
    // const {dataGuncelle,sendData } = useContext(TesisContext);
    return <RtableCell key={create_UUID()}>
    <RtableCellHeading>{baslik}</RtableCellHeading>
    <RtableCellContent>
        <input
            disabled={name!=="created_date"&&editperm ? false : true}
            defaultValue={value}
            name={name}
            // onBlur={(e) => sendData(e)}
            // onChange={(e) => dataGuncelle(e.target, i)}
            onBlur={(e) => updateTesis(e.target, i,_id)}
            key={create_UUID()}
        />
    </RtableCellContent>
</RtableCell>
}