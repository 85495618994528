import React, { useContext,memo, useEffect } from 'react'
import {
    RtableCellContent,
    RtableCell,
} from './style'
import create_UUID from '../../utils/uuid'
import PermissionContext from '../../store/permissionContext';
import TesisContext from '../../store/tesis/tesisContext'
 import DeleteTesisContext from '../../store/deleteTesisContext'

const Checker = memo(({onCheckToggle})=>{
    return <input 
    key={create_UUID()} 
    type="checkbox"
    defaultChecked={false}
    // checked={checked} 
    onChange={onCheckToggle}
    />
},[])
const CellAdi = memo((props)=>{
    const { updateTesis,updateCheckboxTesis } = useContext(TesisContext);
    const { dispatchSilListe } = useContext(DeleteTesisContext);
    const { editperm } = useContext(PermissionContext);
    const { x, i } = props;
    console.log("render check")
    console.log(x)
    return <RtableCell key={create_UUID()}>
        <RtableCellContent key={create_UUID()}>
            {/* <Checker 
            onCheckToggle={(e) => dispatchSilListe({ type: 'ADD_LIST', _id: x._id })}
            /> */}
            <input key={create_UUID()}
                type="checkbox"
                checked={x.select}
                name="select"
                // defaultChecked={false}
                onChange={(e) => updateCheckboxTesis(e,i)}
            />
            <textarea row="1"
                disabled={editperm ? false : true}
                key={create_UUID()}
                defaultValue={x.adi}
                name="adi"
                // onBlur={(e) => sendData(e)}
                // onChange={(e) => updateTesis(e.target, i)}
                onBlur={(e) => updateTesis(e.target, i,x._id)}
            />
        </RtableCellContent>
    </RtableCell>
},[])
export default CellAdi