import React, { createContext, useReducer } from 'react'
import { tesisCRUDReducer } from '../reducers/DeleteTesisReducer'
const DeleteTesisContext = createContext()

export const DeleteTesisProvider = ({ children }) => {
  const [silListe, dispatchSilListe] = useReducer(tesisCRUDReducer, []);

  const value = {
    silListe,
    dispatchSilListe,

  }

  return <DeleteTesisContext.Provider value={value}>{children}</DeleteTesisContext.Provider>
}

export default DeleteTesisContext;