import React, { useContext} from 'react';
import styled from 'styled-components'
import ModalContext from '../../store/modalContext'
import PostTesisContext from '../../store/postTesisContext';
import Form from './form'
const CloseButton = styled.span`
    color: '#25214d';
    position: absolute;
    top: 10px;
    right: 25px;
    font-size: 35px;
    font-weight: bold;
    &:hover,
    &:focus {
        color: #999;
        text-decoration: none;
        cursor: pointer;
}
`
const Modal = styled.div`
    position:fixed;
    flex-direction:row;
    align-content:center;
    align-items:center;
    justify-content:center;
    top:0px;
    left:0px;
    width:100%;
    height:100%;
    background-color:#ffffff40;
    display:${(props) => props.show ? 'flex' : 'none'};
`
export default function ModalComponent({ children }) {
  // const showHideClassName = show ? "modal display-block" : "modal display-none";
  let { postTesisData, ekleTesis } = useContext(PostTesisContext);
  const { show,dispatchModal} = useContext(ModalContext);
 
  
  function postVeriFunc(e) {
    
    ekleTesis(e)

    console.log(postTesisData)
  }
  

  return (
    <Modal show={show}>
      
      <Form postVeriFunc={postVeriFunc}/>

      {children}
      <CloseButton onClick={() => dispatchModal({type:'CLOSE'})}>&times;</CloseButton>
    </Modal>
  );
};