import React from 'react';
// import socketIOClient from "socket.io-client";
import './App.css';
import { BrowserRouter as Router } from "react-router-dom";
import Header from './components/header';
// import Footer from './components/footer';
import Routes from './routes';
import { PermissionProvider } from './store/permissionContext';
import  TesisProvider  from './store/tesis/tesisProvider'
import { ModalProvider } from './store/modalContext';
import { PostTesisProvider } from './store/postTesisContext';
import { LayoutProvider } from './store/layoutContext';
import { DeleteTesisProvider } from './store/deleteTesisContext';
function App() {
  return (<PermissionProvider>
    <DeleteTesisProvider>
      <ModalProvider>
        <LayoutProvider>
          <PostTesisProvider>
            <TesisProvider>
              <Router>
                <Header />
                <Routes />
                {/* <Footer /> */}
              </Router>
            </TesisProvider>
          </PostTesisProvider>
        </LayoutProvider>
      </ModalProvider>
    </DeleteTesisProvider>

  </PermissionProvider>



  );
}

export default App;
