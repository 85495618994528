import React, { useContext } from 'react';
import Filter from './filter';
import { Plus, FilterGroup } from './style'
import PermissionContext from '../../store/permissionContext'
import ModalContext from '../../store/modalContext';
import LayoutContext from '../../store/layoutContext';
import DeleteTesisContext from '../../store/deleteTesisContext'
import TesisContext from '../../store/tesis/tesisContext'
export default function FilterPanel() {
    const { dispatchModal } = useContext(ModalContext);
    const { writeperm, deleteperm } = useContext(PermissionContext);
    const { showlayout, dispatch } = useContext(LayoutContext);
    const { deleteCheckboxTesis } = useContext(TesisContext);
    // const{ dispatchSilListe } =useContext(DeleteTesisContext);
    return <FilterGroup>
        <Filter />
        <Plus 
        disabled={writeperm ? false : true} 
        onClick={() => dispatchModal({type:'OPEN'})}>+ Tesis</Plus>
        <Plus onClick={() => dispatch({type:'SIMPLE',value:!showlayout})}>Görünüm</Plus>
        <Plus 
        disabled={deleteperm ? false : true} 
        onClick={() => deleteCheckboxTesis()}>Sil</Plus>
    </FilterGroup>
}