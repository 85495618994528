import React, { useReducer } from 'react';
import TesisContext from './tesisContext';
import tesisReducer from './tesisReducer';
const url = "https://sudunyasimuhasebe.bestbilgisayar.org";

const TesisProvider = props => {
    const initialState = {
        tesisDatas: [],
        currentBlogPost: null,
        loading: true,
        deleteList: []
    };

    const [state, dispatchTesis] = useReducer(tesisReducer, initialState);

    // Get all Posts
    const getAllTesis = async () => {
        try {
            dispatchTesis({ type: 'SENDING_REQUEST' });
            const res = await fetch(url + '/tesis');
            const data = await res.json();
            dispatchTesis({ type: 'REQUEST_FINISHED' });
            const dataAndSelect = data.map(d => {
                return {
                    ...d,
                    select: false
                }
            })
            console.log(dataAndSelect)
            dispatchTesis({ type: 'SET_POSTS', payload: dataAndSelect.reverse() });
        } catch (err) {
            console.log(err);
        }
    };
    const updateTesis = async (objectkey, i, id) => {
        try {
            console.log(objectkey)
            console.log(objectkey.name)
            console.log(id)
            console.log(i)
            const data = state.tesisDatas;
            data[i][objectkey.name] = objectkey.value;
            console.log(data)
            dispatchTesis({ type: 'UPDATE_LIST_ITEM', payload: data });

            const requestOptions = {
                method: 'PUT',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ [objectkey.name]: objectkey.value })
            };
            fetch(url + `/tesis/${id}`, requestOptions)
                .then(response => getAllTesis())
        } catch (err) {
            console.log(err);
        }
    };
    const updateCheckboxTesis = async (objectkey, i) => {
        try {
            console.log(objectkey)
            console.log(objectkey.name)
            console.log(i)
            const data = state.tesisDatas;
            data[i]['select'] = true;
            console.log(data)
            dispatchTesis({ type: 'UPDATE_LIST_ITEM', payload: data });
            dispatchTesis({ type: 'DELETE_LIST_ITEM', payload: data[i]._id });
        } catch (err) {
            console.log(err);
        }
    };
    const deleteCheckboxTesis = async () => {
        try {
            console.log(state)
            const requestOptions = {
                method: 'DELETE',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    list: state.deleteList
                })
            };
            fetch(url + `/tesisRemovemany`, requestOptions).then(response=>getAllTesis())
            dispatchTesis({ type: 'REST_DELETE_LIST_ITEM' });
        } catch (err) {
            console.log(err);
        }
    };
    const updateTesisNumune = (data, id) => {
        console.log(data)
        const requestOptions = {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ [data.target.name]: data.target.value })
        };
        fetch(url + `/numunetipleri/${id}`, requestOptions)
            .then(response => getAllTesis())
    }
    const filterTesis = async (data) => {
        const apiCall = await fetch(url + '/tesis');
        const tesis = await apiCall.json();
        console.log(tesis)
        const dat = tesis.filter(word => word.adi.toLowerCase().indexOf(data.toLowerCase()) > -1);

        dispatchTesis({ type: 'SET_POSTS', payload: dat });

    }
    const tesisNumuneAktif = (data) => {
        console.log(data)
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                gida: "0",
                su_mik: "0",
                su_kim: "0",
                swap: "0",
                mantar: "0",
                leg: "0",
                ortam_petrisi: "0",
                havuz_mikrobiyoloji_ozel: "0",
                tesis: data
            })
        };
        fetch(url + `/numunetipleri`, requestOptions)
            .then(response => getAllTesis())

    }
    return (
        <TesisContext.Provider
            value={{
                tesisDatas: state.tesisDatas,
                currentBlogPost: state.currentBlogPost,
                loading: state.loading,
                getAllTesis: getAllTesis,
                updateTesis,
                updateTesisNumune,
                filterTesis,
                tesisNumuneAktif,
                updateCheckboxTesis,
                deleteList: state.deleteList,
                deleteCheckboxTesis
            }}
        >
            {props.children}
        </TesisContext.Provider>
    );
};

export default TesisProvider;