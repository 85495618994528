import React, { useState } from 'react';
import styled from 'styled-components';
const FormLayout = styled.div`
    width:400px;
    height:300px;
    background-color:white;
    padding:40px;
    display:flex;
    box-shadow: 0 2px 10px rgba(0,0,0,0.05);
    flex-direction:column;
    p{
            flex-basis:100%;
            font-weight:900;
            font-size:24px;
        }
    form{
        flex-basis:100%;
        display:flex;
        flex-direction:column;
    }
`;
const InputWrap = styled.div`
    flex-basis:100%;
    display:flex;
    flex-direction:column;
    label{
        flex-basis:100%;
        text-align:left;
        font-weight:700;
        font-size:12px;
    }
    input{
        border:none;
        flex-basis:100%;
        border-radius:10px;
        padding:10px;
        background-color:#f7faff;
        outline:none;   
        font-family: 'Quicksand', sans-serif;
        margin:5px 5px;
        transition:500ms;
        font-size:16px;
        &:focus{
            transition:500ms;
            box-sizing:border-box;
            border:1px solid #3346d3;
        }
    }
    &:last-child{
        input{
        flex-basis:none;
        width:100px;
        transition:300ms;
        background-color:#3346d3;
        color:white;
        &:hover{
            transition:300ms;
            background-color:#25214d;
        }
        }
        
    }
`;
export default function From({postVeriFunc}) {
    const [formdata, setForm] = useState({
        tesisAdi: '',
        tesisKodu: '',
        tesisBakanlikKodu:'',
        tesisHavuzAdeti: '',
    })
    function formSetFunc(e) {
        setForm({
            ...formdata,
            [e.target.name]: e.target.value
        })
    }
    function Gonder(){
        postVeriFunc(formdata)
    }
    
    const arr = Object.entries(formdata);
    return <FormLayout>
        <p>Tesis Ekle</p>
        <form onSubmit={()=>Gonder()}>
        {arr.map((x,i)=>{

            return <InputWrap key={i}>
            <label>
                {x[0]==="tesisAdi"?"Tesis Adı":false}
                {x[0]==="tesisKodu"?"Tesis Kodu":false}
                {x[0]==="tesisHavuzAdeti"?"Havuz Adeti":false}
                {x[0]==="tesisBakanlikKodu"?"Tesis Bakanlık Kodu":false}
            </label>
            <input
            required
            placeholder="Gerekli Alan"
 
            value={formdata[x[0]]}
            name={x[0]}
            onChange={(e) => { formSetFunc(e) }} />
            </InputWrap> 
        })}
        {/* <input
                value={formdata.tesisAdi}
                name="tesisAdi"
                onChange={(e) => { formSetFunc(e) }} /> */}
        <InputWrap><input type="submit" value="Kaydet" /></InputWrap>
    </form>
        </FormLayout>
}