export default (state, action) => {
    switch (action.type) {
        case 'SET_POSTS':
            return {
                ...state,
                tesisDatas: action.payload
            };
        case 'SET_POST':
            return {
                ...state,
                currentBlogPost: action.payload
            };
        case 'SENDING_REQUEST':
            return {
                ...state,
                loading: true
            };
        case 'REQUEST_FINISHED':
            return {
                ...state,
                loading: false
            };
        case 'UPDATE_LIST_ITEM':
            console.log("update tesis")
            return {
                ...state,
                tesisDatas: action.payload
            };
        case 'DELETE_LIST_ITEM':
            console.log("update tesis")
            return {
                ...state,
                deleteList: [...state.deleteList,action.payload]
            };
            case 'REST_DELETE_LIST_ITEM':
            console.log("reset tesis")
            return {
                ...state,
                deleteList: []
            };
        default:
            return state;
    }
};