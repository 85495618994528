import React, { useReducer, createContext } from 'react'
import {layoutReducer} from '../reducers/LayoutReducer';
const LayoutContext = createContext()

export const LayoutProvider = ({ children }) => {
  const [showlayout, dispatch] = useReducer(layoutReducer,true)


  const value = {
    showlayout,
    dispatch
  }


  return <LayoutContext.Provider value={value}>{children}</LayoutContext.Provider>
}

export default LayoutContext;