import React, { Fragment } from 'react';
import TableEdit from '../../components/table'
import FilterGroup from '../../components/table/filterGroup'
import Modal from '../../components/modal';
function Home() {

    return <Fragment>    
                    <FilterGroup/>
                    <TableEdit />
                    <Modal />
    </Fragment>
}

export default {
    routeProps: {
        path: '/',
        component: Home,
        exact: true,
        private:false
    },
    name: 'Ana Sayfa',
    headerLocale: true
    //icon:<IoMdAnalytics />
}