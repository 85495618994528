import React, { useContext, useState } from 'react';
import { useHistory} from "react-router-dom";
import styled from 'styled-components';
import PermissionContext from '../../store/permissionContext';
const FormLayout = styled.div`
    width:400px;
    /* height:300px; */
    border-radius:10px;
    background-color:white;
    padding:40px;
    display:flex;
    box-shadow: 0 2px 10px rgba(0,0,0,0.05);
    flex-direction:column;
    margin:0px auto;
    p{
            flex-basis:100%;
            font-weight:900;
            font-size:24px;
        }
    form{
        flex-basis:100%;
        display:flex;
        flex-direction:column;
    }
    `;
const InputWrap = styled.div`
    /* flex-basis:100%; */
    display:flex;
    flex-direction:column;
    label{
        /* flex-basis:100%; */
        text-align:left;
        font-weight:700;
        font-size:12px;
    }
    input{
        border:none;
        /* flex-basis:100%; */
        border-radius:10px;
        padding:10px;
        background-color:#f7faff;
        outline:none;   
        font-family: 'Quicksand', sans-serif;
        margin:5px 5px;
        transition:500ms;
        font-size:16px;
        &:focus{
            transition:500ms;
            box-sizing:border-box;
            border:1px solid #3346d3;
        }
    }
    &:last-child{
        input{
        flex-basis:none;
        width:100px;
        transition:300ms;
        background-color:#3346d3;
        color:white;
        &:hover{
            transition:300ms;
            background-color:#25214d;
        }
        }
        
    }
`;

function Login() {
    let history = useHistory();
    const [username,setUsername] = useState(false);
    const [password,setPassword] = useState(false);
    const { login } = useContext(PermissionContext);
    function Gonder(){
        login(username,password)
        history.push('/')
    }
    return <FormLayout>
        <form
            onSubmit={() => Gonder()}
        >
            <InputWrap>
                <label>
                    Kullanıcı Adı
            </label>
                <input
                    required
                    placeholder="Gerekli Alan"
                    onChange={(e)=>setUsername(e.target.value)}
                    name={"username"}
                // onChange={} 
                />
            </InputWrap>
            <InputWrap>
                <label>
                    Şifre
            </label>
                <input
                    required
                    type="password"
                    placeholder="Gerekli Alan"
                    name={"password"}
                    onChange={(e)=>setPassword(e.target.value)}
                />
            </InputWrap>
            <InputWrap><input type="submit" value="Oturum Aç" /></InputWrap>

        </form>
    </FormLayout>

}

export default {
    routeProps: {
        path: '/login',
        component: Login,
        private: true
    },
    name: 'Login',
    headerLocale: false
    //icon:<IoMdAnalytics />
}