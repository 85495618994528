import styled from 'styled-components';

export const Wrapper = styled.div`
    width: 100%;
    margin: 1em auto;
    padding: 0px 1em;
    box-sizing: border-box;
    border-radius:10px;
`;
export const Rtable = styled.div`
    border-radius:10px;
    overflow:auto;
    display: flex;
    flex-wrap: wrap;
    margin: 0 0 3em 0;
    padding: 0;
    box-shadow: 0 2px 10px rgba(0,0,0,0.05);
    box-sizing: border-box;
    max-height:calc( 100vh - 160px);
    &::-webkit-scrollbar-track
        {
            -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
            border-radius: 10px;
            background-color: #F5F5F5;
        }

        &::-webkit-scrollbar
        {
            border-radius: 10px;
            width: 4px;
            background-color: #55e0ff;
        }

        &::-webkit-scrollbar-thumb
        {
            border-radius: 10px;
            -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
            background-color: #55e0ff;
        }
    @media all and (max-width:1000px) {
            display:block;
            width: 100%;
            padding: 1em;
            box-shadow: none;
        }
`;



export const RtableCellHeading = styled.div`
    display: none;
    @media all and (max-width:1000px) {
        display: inline-block;
        flex: 1;
        font-weight: 700;
        border-right: 1px solid #ccc;
        margin-right: 1em;
    }
`;
export const RtableCellContent = styled.div`
    color:#6d7474;
    font-size:14px;
    font-family: 'Quicksand', sans-serif;
    font-weight:600;    
    height:20px;
    input[type="checkbox"]{
        width:15px;
        cursor:pointer;
        &:after{
            line-height: 1em;
            content: '';
            display: inline-block;
            width: 15px;
            height: 15px;
            margin-top: -1px;
            margin-left: -1px;
            border: 1px solid #3346d3;
            border-radius: 0.25em;
            background: rgb(224,224,224);
        }
        &:checked:after {
            width: 15px;
            content: '✔';
            text-align: center;
            color:#3346d3;
            margin-top: -1px;
            margin-left: -1px;
            display: inline-block;
            background-color:#F5F5F5;
            height: 15px;
            /* border: 3px solid #00ff00; */
        }
    }
    
    textarea{
        text-align:left;
        /* width:auto; */
        width:calc(100% - 30px);
        height:auto;
        border:none;
        resize:none;
        padding:0px;
        height:20px;
        word-break: break-all;
        outline:none;
        background:none;
        color:#6d7474;
        font-size:14px;
        font-family: 'Quicksand', sans-serif;
        font-weight:600;
        
        &:first-child{
       
    
        }
        &::-webkit-scrollbar-track
        {
            -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
            border-radius: 10px;
            background-color: #F5F5F5;
        }

        &::-webkit-scrollbar
        {
            border-radius: 10px;
            width: 4px;
            background-color: #55e0ff;
        }

        &::-webkit-scrollbar-thumb
        {
            border-radius: 10px;
            -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
            background-color: #55e0ff;
        }
    }
    input{
        text-align:left;
        width:auto;
        height:auto;
        border:none;
        resize:none;
        padding:0px;
        height:20px;
        word-break: break-all;
        outline:none;
        background:none;
        color:#6d7474;
        font-size:14px;
        font-family: 'Quicksand', sans-serif;
        font-weight:600;
        &::-webkit-scrollbar-track
        {
            -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
            border-radius: 10px;
            background-color: #F5F5F5;
        }

        &::-webkit-scrollbar
        {
            border-radius: 10px;
            width: 4px;
            background-color: #55e0ff;
        }

        &::-webkit-scrollbar-thumb
        {
            border-radius: 10px;
            -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
            background-color: #55e0ff;
        }
    }
    
    @media all and (max-width:1000px) {
        text-align:left;
        input{
            text-align:left;
        }
        
        textarea{
            text-align:left;
            width: auto !important;
        }
           

    }
`
export const NumuneAktif= styled.button`
    background:none;
    font-family: 'Quicksand', sans-serif;
    color:#3346d3;
    border:none;
    font-weight:bold;
    cursor:pointer;
`;
export const RtableRow = styled.div`
    width: 100%;
    display: flex;
    &:hover ${RtableCellContent} {
        color:white !important;
        textarea,input{
            color:white !important;
        }
    }
    &:hover ${NumuneAktif} {
               color:white;
             }
    &:hover ${RtableCellHeading} {
        color:#55e0ff !important;
        textarea,input{
            color:#55e0ff !important;
        }
    }
    &:hover{
          background-color:#3346d3;
          color:white !important;
      }
    @media all and (max-width:1000px) {
        border-radius:10px;
        background:white;
        box-sizing: border-box;
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 2em;
        box-shadow: 0 2px 10px rgba(0,0,0,0.05);
        }
`;

export const RtableCell = styled.div`
    box-sizing: border-box;
    flex-grow: 1;
    flex-basis:25%;
    padding: ${props => props.header ? '1em' : '0.8em 1.2em'};
    overflow: hidden;
    list-style: none;
    word-break: break-all;
    color: ${props => props.header ? 'white' : 'black'};
    &:nth-child(2){
        flex-basis:5%;
        text-align:left;
        textarea ,input{
            text-align:center;
            width:100%;
        }
    }
    &:nth-child(4){
        flex-basis:10%;
        text-align:left;
        textarea ,input{
            text-align:center;
        }
    }
    &:nth-child(3){
        flex-basis:10%;
        text-align:left;
        textarea ,input{
            text-align:center;
        }
    }
    &:nth-child(5){
        flex-basis:15%;
        text-align:center;
        textarea , input{
            text-align:center;
        }
    }
    @media all and (max-width:1000px) {
        display:${props => props.header ? 'none' : 'flex'};
        flex-basis:100%;
        align-items: center;
        &:nth-child(1){
            font-weight: 700;
            flex-basis:100%;
            order: -1;
            input[type="checkbox"]{
        width:15px;
        cursor:pointer;
        &:after{
            line-height: 1em;
            content: '';
            display: inline-block;
            width: 15px;
            height: 15px;
            margin-top: 4px;
            margin-left: -1px;
            border: 1px solid #3346d3;
            border-radius: 0.25em;
            background: rgb(224,224,224);
        }
        &:checked:after {
            width: 15px;
            content: '✔';
            text-align: center;
            color:#3346d3;
            margin-top: -1px;
            margin-left: -1px;
            display: inline-block;
            background-color:#F5F5F5;
            height: 15px;
            /* border: 3px solid #00ff00; */
        }
    }
            textarea , input{
                color:black;
                width:auto;
                font-size:18px;
                text-align:left;
                height:30px;
                margin-left:10px;
            }
            ${RtableCellContent} {
               flex-basis:100%;
             }
        }
        &:nth-child(5){
            flex-basis:100%;
            text-align:left;
            textarea , input{
                text-align:left;
            }
            ${RtableCellHeading} {
               flex:none;
               flex-basis:40%;
             }
        }
        &:nth-child(2){
        flex-basis:100%;
        text-align:left;
            textarea , input{
                text-align:left;
            }
            ${RtableCellHeading} {
               flex:none;
               flex-basis:40%;
             }
        }
        &:nth-child(3){
            flex-basis:100%;
            text-align:left;
            textarea , input{
                text-align:left;
            }
            ${RtableCellHeading} {
               flex:none;
               flex-basis:40%;
             }
        }
        &:nth-child(4){
            flex-basis:100%;
            text-align:left;
            textarea , input{
                text-align:left;
            }
            ${RtableCellHeading} {
               flex:none;
               flex-basis:40%;
             }
        }
    }
`;
export const RtableCellNumune = styled.div`
    box-sizing: border-box;
    flex-grow: 1;
    flex-basis:25%;
    padding: ${props => props.header ? '1em' : '0.8em 1.2em'};
    overflow: hidden;
    list-style: none;
    word-break: break-all;
    color: ${props => props.header ? 'white' : 'black'};
    @media all and (max-width:1000px) {
        display:${props => props.header ? 'none' : 'flex'};
        flex-basis:100%;
        align-items: center;
        &:nth-child(1){
            font-weight: 700;
            flex-basis:100%;
            order: -1;
            textarea , input{
                color:black;
                width:100%;
                font-size:18px;
                text-align:center;
                height:30px;
            }
            ${RtableCellContent} {
               flex-basis:100%;
             }
        }
        &:nth-child(5){
            flex-basis:100%;
            text-align:left;
            textarea , input{
                text-align:left;
            }
            ${RtableCellHeading} {
               flex:none;
               flex-basis:40%;
             }
        }
        &:nth-child(2){
        flex-basis:100%;
        text-align:left;
            textarea , input{
                text-align:left;
            }
            ${RtableCellHeading} {
               flex:none;
               flex-basis:40%;
             }
        }
        &:nth-child(3){
            flex-basis:100%;
            text-align:left;
            textarea , input{
                text-align:left;
            }
            ${RtableCellHeading} {
               flex:none;
               flex-basis:40%;
             }
        }
        &:nth-child(4){
            flex-basis:100%;
            text-align:left;
            textarea , input{
                text-align:left;
            }
            ${RtableCellHeading} {
               flex:none;
               flex-basis:40%;
             }
        }
        &:nth-child(6){
            flex-basis:100%;
            text-align:left;
            textarea , input{
                text-align:left;
            }
            ${RtableCellHeading} {
               flex:none;
               flex-basis:40%;
             }
        }
        &:nth-child(7){
            flex-basis:100%;
            text-align:left;
            textarea , input{
                text-align:left;
            }
            ${RtableCellHeading} {
               flex:none;
               flex-basis:40%;
             }
        }
        &:nth-child(8){
            flex-basis:100%;
            text-align:left;
            textarea , input{
                text-align:left;
            }
            ${RtableCellHeading} {
               flex:none;
               flex-basis:40%;
             }
        }
        &:nth-child(9){
            flex-basis:100%;
            text-align:left;
            textarea , input{
                text-align:left;
            }
            ${RtableCellHeading} {
               flex:none;
               flex-basis:40%;
             }
        }
    }
    `;
export const Plus = styled.button`
     border:none;
     padding:10px;
     background-color:#f5f5f5;
     color:#999cad;
     border-radius:5px;
     cursor:pointer;
     margin:0px 5px;
     font-family: 'Quicksand', sans-serif;
     outline:none;
     &:hover{
        color:white;
        background-color:#3346d3;
     }
     @media all and (max-width:1000px) {
        margin-top:10px;
    }
        `
export const FilterGroup = styled.div`
display:flex;
flex-wrap:wrap;
width:calc( 100% - 2em );
background:white;
padding:10px;
border-radius:10px;
margin: 20px auto;
box-sizing:border-box;
flex-direction: row;
justify-content: flex-start;
/* float:left; */
`;
export const EmptyTesis = styled.div`
flex-basis:100%;
text-align:center;
padding:20px;
span{
    color:#3346d3;
    cursor:pointer;
}
`

