import React,{useContext} from 'react';
import PermissionContext from './store/permissionContext';
import modules from './modules';
import { Switch, Route,Redirect } from "react-router-dom";


export default function Routes() {
    const { authenticated } = useContext(PermissionContext);
   
    return (
        <Switch>
            {modules.map(module => {
            if(module.routeProps.path==="/login"){
                console.log("ust")
                return (<Route
                // {...module.routeProps}
                exact={module.routeProps.exact}
                path={module.routeProps.path}
                pageName={module.name}
                key={module.name}
                render={()=><module.routeProps.component/>}
            />)
            }else{
                console.log("alt")
                return <Route
                    // {...module.routeProps}
                    exact={module.routeProps.exact}
                    path={module.routeProps.path}
                    pageName={module.name}
                    key={module.name}
                    render={()=>authenticated?(<module.routeProps.component/>):(<Redirect
                                to={{
                                pathname: "/login"
                                }}
                            />)}
                />
            }
             
                

                

                            })}
        </Switch>
    )
}