import React, { useContext } from 'react';
import TesisContext from '../../store/tesis/tesisContext'
import styled from 'styled-components';
const FilterButton = styled.input`
border:none;
border-radius:10px;
padding:10px;
background-color:#f7faff;
outline:none;   
font-family: 'Quicksand', sans-serif;
margin:0px 5px;
@media all and (max-width:1000px) {
        display: inline-block;
        flex: 1;
        flex-basis:100%;
    }
`
export default function Filter(){
    const {filterTesis} = useContext(TesisContext);
    return <FilterButton placeholder="Kurum Adı"type="textbox" onChange={(e)=>filterTesis(e.target.value)}/>

}