export const modalReducer = (state,action)=>{
    switch (action.type) {
        case 'OPEN':
            return true

            case 'CLOSE':
                return false
            default:
                return state
    }
}