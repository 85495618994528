import React, { useContext, Fragment } from 'react';
import styled from 'styled-components';
import LayoutContext from '../../store/layoutContext';
// import modules from '../../modules'
// import { Link } from 'react-router-dom'
const HeaderRtableRow = styled.div`
    width: 100%;
    display: flex;
    position:sticky;
    top:0;
    @media all and (max-width:1000px) {
        display:${props => props.header ? 'none' : 'flex'};
        box-sizing: border-box;
        width: 100%;
      flex-wrap: wrap;
      margin-bottom: 2em;
      box-shadow: 0 0 40px rgba(0,0,0,0.2);
    }
      
`;
const HeaderRtableCell = styled.div`
    box-sizing: border-box;
    flex-grow: 1;
    flex-basis:25%;
    padding: ${props => props.header ? '1em' : '0.8em 1.2em'}; 
    overflow: hidden;
    list-style: none;
    background-color:${props => props.header ? 'white' : 'white'}; 
    color: ${props => props.header ? '#25214d' : 'black'};
    font-weight:700;
    &:nth-child(2){
        flex-basis:5%;
        text-align:center;
    }
    &:nth-child(3){
        flex-basis:10%;
        text-align:center;
    }
    &:nth-child(4){
        flex-basis:10%;
        text-align:center;
    }
    &:nth-child(5){
        flex-basis:15%;
        text-align:center;
    }
    /* color:#25214d; */
    @media all and (max-width:1000px) {
        display:${props => props.header ? 'none' : 'flex'};
        flex-basis:100%;
        align-items: center;
        &:nth-child(1){
            color: white;
            font-weight: 700;
            order: -1;
        }
        &:nth-child(4){
        text-align:left;
        }
        &:nth-child(5){
        text-align:left;
        }
    }
   
`;
const HeaderRtableCellNumune = styled.div`
    box-sizing: border-box;
    flex-grow: 1;
    flex-basis:25%;
    padding: ${props => props.header ? '1em' : '0.8em 1.2em'}; 
    overflow: hidden;
    list-style: none;
    background-color:${props => props.header ? 'white' : 'white'}; 
    color: ${props => props.header ? '#25214d' : 'black'};
    font-weight:700;
    @media all and (max-width:1000px) {
        display:${props => props.header ? 'none' : 'flex'};
        flex-basis:100%;
        align-items: center;
        &:nth-child(1){
            color: white;
            font-weight: 700;
            order: -1;
        }
        &:nth-child(4){
        text-align:left;
        }
        &:nth-child(5){
        text-align:left;
        }
    }
   
`;
export default function Head(props) {
    const { showlayout } = useContext(LayoutContext);
    const displayHeader = props.displayHeader;
    // return <Rtable_row>{Object.entries(props.veri[0]).forEach(([key, value]) => <Rtable_cell header={true}> {value}   </Rtable_cell>
    return <HeaderRtableRow header={true}>
        <HeaderRtableCell header={true} key="adi">Adı</HeaderRtableCell>
        {showlayout ? <Fragment>
            <HeaderRtableCellNumune header={true} key="gida">Gıda</HeaderRtableCellNumune>
            <HeaderRtableCellNumune header={true} key="sumik">Su Mik.</HeaderRtableCellNumune>
            <HeaderRtableCellNumune header={true} key="sukim">Su Kim.</HeaderRtableCellNumune>
            <HeaderRtableCellNumune header={true} key="swap">Swap</HeaderRtableCellNumune>
            <HeaderRtableCellNumune header={true} key="mantar">Mantar</HeaderRtableCellNumune>
            <HeaderRtableCellNumune header={true} key="leg">Leg</HeaderRtableCellNumune>
            <HeaderRtableCellNumune header={true} key="ortampetrisi">Ortam Petrisi</HeaderRtableCellNumune>
            <HeaderRtableCellNumune header={true} key="havuzmikozel">Havuz Mik</HeaderRtableCellNumune>
        </Fragment> : Object.entries(props.veri[0]).map((key, value) => displayHeader.map((x) => {
            if (x.key === key[0]) {
                if (x.key !== "adi") {
                    return <HeaderRtableCell header={true} key={value}>{x.display_name}</HeaderRtableCell>
                } else {
                    return false
                }

            } else {
                return false
            }
        })
        )}

    </HeaderRtableRow>


}