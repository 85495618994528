import React, { useState, createContext } from 'react'


const PermissionContext = createContext()

export const PermissionProvider = ({ children }) => {
  const prevAuth = window.localStorage.getItem('authenticated') || false;
  const prevAuthBody = window.localStorage.getItem('authBody') || false;
  const prevAuthBodyConvert =JSON.parse(prevAuthBody);
  let {editLocal,writeLocal, readLocal, deleteLocal} = prevAuthBodyConvert;
  const [authenticated, setAuthenticated] = useState(prevAuth);
  const [authBody, setAuthBody] = useState(prevAuthBodyConvert);
  const [editperm, setEdit] = useState(editLocal);
  const [writeperm, setWrite] = useState(writeLocal);
  const [readperm, setRead] = useState(readLocal);
  const [deleteperm, setDelete] = useState(deleteLocal);
  function Logoout(){
    setAuthenticated(false);
    localStorage.clear();
  }
  function login(username,password){
    if(username==="admin" && password==="admin"){
      const permData = JSON.stringify({
        editLocal:true,
        writeLocal:true,
        readLocal:true,
        deleteLocal:true
      })
      setAuthenticated(true)
      setAuthBody(permData)
      setEdit(true)
      setWrite(true)
      setRead(true)
      setDelete(true)
      window.localStorage.setItem('authenticated', true);
      window.localStorage.setItem('authBody', permData);
    }else if(username==="personel" && password==="personel"){
      const permData = JSON.stringify({
        editLocal:false,
        writeLocal:false,
        readLocal:true,
        deleteLocal:false
      })
      setAuthenticated(true)
      setAuthBody(permData)
      setEdit(false)
      setWrite(false)
      setRead(true)
      setDelete(false)
      window.localStorage.setItem('authenticated', true);
      window.localStorage.setItem('authBody', permData);
    }else{
      setAuthenticated(false);
      localStorage.clear();
    }
    
  }
  // useEffect(
  //   () => {
  //     window.localStorage.setItem('authenticated', authenticated);
  //     window.localStorage.setItem('authBody', prevAuthBody);
  //   },
  //   [authenticated, prevAuthBody]
  // );
  const value = {
    editperm,
    writeperm,
    readperm,
    deleteperm,
    setAuthenticated,
    login,
    Logoout,
    authBody, 
    authenticated,
    setAuthBody,
    setEdit,
    setWrite,
    setRead,
    setDelete
  }


  return <PermissionContext.Provider value={value}>{children}</PermissionContext.Provider>
}

export default PermissionContext;