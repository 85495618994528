import React, { useContext,useReducer, useEffect, Fragment, useCallback } from 'react';
import Head from './tableHead'
import NoData from './noData';
import TesisContext from '../../store/tesis/tesisContext'
import LayoutContext from '../../store/layoutContext';
import PermissionContext from '../../store/permissionContext';
import {
    Wrapper,
    Rtable,
    RtableCellHeading,
    RtableCellContent,
    RtableRow,
    RtableCellNumune,
} from './style'
import create_UUID from '../../utils/uuid'
import {displayHeader,displayData} from './tableHeaderArray'
import CellAdi from './cellAdi';
import NumuneEkleButton from './numuneEkleButton'
import CellTesisDetail from './cellTesisDetail'


export default function Table() {
    const { editperm } = useContext(PermissionContext);
    const { showlayout } = useContext(LayoutContext);
    // const { state,dispatchTesis } = useContext(TesisContext);
    const { getAllTesis, tesisDatas,updateTesisNumune  } = useContext(TesisContext);

    
    // function inputNumuneSet(e, numuneIndex, tesisIndex) { dispatchTesis({type:'UPDATE_NUMUNE_ITEM',objectkey:e.target, numuneIndex:numuneIndex, tesisIndex:tesisIndex}) }
    useEffect(() => {
        console.log('hello')
        getAllTesis();
    }, []);
    console.log("render check")
    return (<Wrapper>
        <Rtable>
            {tesisDatas ? <Head veri={tesisDatas} displayHeader={displayHeader} /> : false}
            {tesisDatas.length > 0 ? tesisDatas.map((x, i) => {
                return <RtableRow key={create_UUID()}>
                    <CellAdi x={x} i={i} />
                    {showlayout ? <Fragment key={create_UUID()}>{x.numuneler.length > 0 ? x.numuneler.map((u, p) => {
                        if (u._id !== false && p === 0) {
                            return Object.entries(u).map((t, d) => displayData.map((s, h) => {
                                if (s.key === t[0]) {
                                    return (<RtableCellNumune key={create_UUID()}>
                                        <RtableCellHeading>
                                            {s.display_name}
                                        </RtableCellHeading>
                                        <RtableCellContent key={create_UUID()}>
                                            <input
                                                disabled={editperm ? false : true}
                                                key={create_UUID()}
                                                defaultValue={t[1] || ""}
                                                _id={u._id}
                                                name={t[0] || ""}
                                                // onBlur={(e) => sendNumuneData(e)}
                                                // onChange={(e) => inputNumuneSet(e, p, i)}
                                                onBlur={(e) => updateTesisNumune(e,u._id)}
                                            />
                                        </RtableCellContent></RtableCellNumune>)
                                } else {
                                    return false
                                }

                            }))

                        } else {
                            return false
                        }

                    }) : <NumuneEkleButton {...x} />}
                    </Fragment> : <Fragment>
                            <CellTesisDetail {...x} value={x.kodu} baslik="Kodu" name="kodu" i={i} />
                            <CellTesisDetail {...x} value={x.bakanlik_kodu} baslik="B. Kodu" name="bakanlik_kodu" i={i} />
                            <CellTesisDetail {...x} value={x.havuz_adeti} baslik="Havuz Adeti" name="havuz_adeti" i={i} />
                            <CellTesisDetail {...x} value={x.createdAt.slice(0, 10)} baslik="Oluşturma Tarihi" name="created_date" i={i} />
                        </Fragment>
                    }
                </RtableRow>
            }) : <NoData />}
        </Rtable>
    </Wrapper>)
}
