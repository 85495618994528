import React,{useContext} from 'react';
import modules from '../../modules'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import Logo from '../../image/logo.png'
import PermissionContext from '../../store/permissionContext';
const HeaderWrap = styled.header`
width:100%;
display:flex;
flex-direction:row;
justify-content:space-between;
/* background-color:red; */
.logo{
  width:150px;
  height:60px;
  /* background:#3346d3; */
  img{
    margin-left:1em;
    height:100%;
    margin-top:10px;
  }
}
ul{
  padding-right:50px;
  list-style:none;
  margin:2em 0em;
  li{
    float:left;
    a{
      padding:0px 10px;
      text-decoration:none;
      color:#a2a4ac;
      font-weight:600;
      letter-spacing:2px;
      font-weight:400;
      font-size:1.1em;
      transition:500ms;
      cursor:pointer;
      &:hover{
        transition:500ms;
        color:#3346d3;
        font-weight:800;
        text-decoration: line-through;
          
      }
    }
    &:last-child{
      text-decoration:none;
      color:#a2a4ac;
      font-weight:600;
      letter-spacing:2px;
      font-weight:400;
      font-size:1.1em;
      transition:500ms;
      cursor:pointer;
      &:hover{
        transition:500ms;
        color:#3346d3;
        font-weight:800;
        text-decoration: line-through;
          
      }
    }
  }
}
@media all and (max-width:1000px) {
  /* background-color:pink; */
  ul{
    padding-right:5px;
  }
}
`
export default function Header(){
  const { authenticated, Logoout} = useContext(PermissionContext);
    return( <HeaderWrap>
      <div className="logo">
        <img src={Logo} alt="logo"/>
      </div>
      <ul>
        {modules.map(module => (
          module.headerLocale ? <li key={module.name}>
            <Link to={module.routeProps.path}>{module.name}</Link>
          </li> : false
        ))}
        {authenticated?<li onClick={()=>Logoout()}>Oturumu Kapat</li>:<li><Link to="/login">Oturum Aç</Link></li>}
        
      </ul>
      </HeaderWrap>

    )
}