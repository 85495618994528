import React, { useState, createContext } from 'react'
const url = "https://sudunyasimuhasebe.bestbilgisayar.org";
const PostTesisContext = createContext()

export const PostTesisProvider = ({ children }) => {
  const [postTesisData, setTesisData] = useState({
    tesisAdi: "", 
    tesisKodu: "",
    tesisBakanlikKodu: "",
    tesisHavuzAdeti: '',
})

const ekleTesis = (e)=>{
  console.log(e)
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(e)
    };
    fetch(url+`/tesis`, requestOptions)
        
}

  const value = {
    postTesisData,
    setTesisData,
    ekleTesis
  }


  return <PostTesisContext.Provider value={value}>{children}</PostTesisContext.Provider>
}

export default PostTesisContext;