import React, { useContext } from 'react';
import {
    RtableCellContent,
    NumuneAktif,
    RtableCellNumune,
} from './style'
import PermissionContext from '../../store/permissionContext';
import TesisContext from '../../store/tesis/tesisContext'
export default function NumuneEkleButton({_id}) {
    const { editperm } = useContext(PermissionContext);
     const {tesisNumuneAktif } = useContext(TesisContext);
    
    return <RtableCellNumune>
        <RtableCellContent>
            <NumuneAktif
                disabled={editperm ? false : true}
                 onClick={(e) => tesisNumuneAktif(_id)}
                >
                Numune Ekle
            </NumuneAktif>
        </RtableCellContent>
    </RtableCellNumune>
}