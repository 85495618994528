import React, { useReducer, createContext } from 'react'
import {modalReducer} from '../reducers/ModalReducer';

const ModalContext = createContext()

export const ModalProvider = ({ children }) => {
  const [show, dispatchModal] = useReducer(modalReducer,false)


  const value = {
    show,
    dispatchModal
  }


  return <ModalContext.Provider value={value}>{children}</ModalContext.Provider>
}

export default ModalContext;