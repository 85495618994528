const url = "http://localhost:5000";
export const tesisCRUDReducer = (state, action) => {
    switch (action.type) {
        case 'ADD_LIST':
         console.log("Add")
            return topluSilEkle(state, action._id)
        case 'DELETE':
            return sendTopluSil(state)
        default:
            return state
    }

}

function topluSilEkle(state, data) {
    
    const listedeBul = state.filter(function (obj) {
        return obj === data;
    });

    if (listedeBul.length === 0) {
        console.log("liste içi")
        return [...state,data];
    }
    else {
        const listedenCikar = state.filter(function (obj) {
            return obj !== data;
        });
        console.log(listedenCikar)
        return [...listedenCikar];
    }


}
function sendTopluSil(state){
    console.log(state)
    const requestOptions = {
      method: 'DELETE',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        list:state
      })
    };
    fetch(url + `/tesisRemovemany`, requestOptions)
    return []
}
