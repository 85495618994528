import React,{useContext} from 'react';
import { EmptyTesis } from './style'
import ModalContext from '../../store/modalContext';
import PermissionContext from '../../store/permissionContext';

export default function NoData() {
    const { writeperm } = useContext(PermissionContext);
    const { setShow } = useContext(ModalContext);
    return <EmptyTesis>
            Herhangi bir tesis kaydı bulunamamıştır. Tesis
            <span 
            disabled={writeperm ? false : true} 
            onClick={() => setShow(true)}>ekle</span>yiniz.
        </EmptyTesis>
}